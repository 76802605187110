import React, { Children } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Outlet, Router } from 'react-router';
import { BrowserRouter, Route, Navigate, Routes, useRoutes } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { createBrowserHistory } from 'history';
import { PUBLIC_PATH, APP_ROUTES } from './shared/config/routes-config';
import ReduxStore from './shared/redux/store';
import { ReactNotifications } from 'react-notifications-component';
import { initializeTrackingTools } from './shared/services/utils/tracking/tracking.service';
import { initializeI18n } from './shared/config/i18n.config';
import { AuthRoutesConfig } from './Modules/AuthModule/shared/config/auth-routes.config';
import { refreshUserSession } from './shared/services/data/user-data/auth.data';
import { AuthStatus } from './shared/enums/auth.status.enum';
import { RouteConfig } from './shared/interfaces/routes-config.interface';
import './index.scss';
import 'react-notifications-component/dist/theme.css';
import CustomTheme from './shared/themes';
import { ApplicationsRoutesConfig } from './Modules/FinanceApplicationsModule/shared/config/applications-routes.config';

const store = ReduxStore;
initializeTrackingTools();
initializeI18n();
// const history = createBrowserHistory({ basename: PUBLIC_PATH });
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

refreshUserSession().then((response) => {
  if (
    response === AuthStatus.UNAUTHORIZED &&
    window.location.href.indexOf(
      (APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).UNAUTHORIZED.FULL_PATH
    ) < 0
  ) {
    console.log('User not premitted to access partner page');
    window.location.href =
      PUBLIC_PATH + (APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).UNAUTHORIZED.FULL_PATH;
    // setTimeout(() => {
    //   history.push((APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).UNAUTHORIZED.FULL_PATH);
    // }, 500);
  }
});
const App = () => {
  console.log('Configure app routes');
  const routes = useRoutes([
    {
      index: true,
      element: <Navigate to={APP_ROUTES.AUTH_MODULE.FULL_PATH} />
    },
    {
      path: APP_ROUTES.AUTH_MODULE.FULL_PATH,
      children: AuthRoutesConfig
    },
    {
      path: APP_ROUTES.APPLICATIONS_MODULE.FULL_PATH,
      children: ApplicationsRoutesConfig
    }
  ]);
  return routes;
};

root.render(
  <React.StrictMode>
    <ReactNotifications />
    <Provider store={store}>
      <BrowserRouter basename={PUBLIC_PATH || '/'}>
        <CustomTheme>
          <App />
        </CustomTheme>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
