// @ts-nocheck
import { ThemeOptions } from '@mui/material';

export default function AppBar(theme: ThemeOptions) {
  return {
    MuiAppBar: {
      styleOverrides: {
        root: {
          [theme.breakpoints.up('md')]: {
            height: theme.spacing(12)
          },
          [theme.breakpoints.down('md')]: {
            height: theme.spacing(10)
          },
          boxShadow: 'none',
          filter: theme.customShadows.appBar,
          zIndex: theme.zIndex.appBar
        },
        colorPrimary: {
          backgroundColor: theme.palette?.primary.light
        }
      }
    }
  };
}
