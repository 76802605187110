import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { APP_ROUTES } from '../../../../shared/config/routes-config';
import { UserRole } from '../../../../shared/enums/user-role.enum';
import { RouteConfig } from '../../../../shared/interfaces/routes-config.interface';
import DefaultLayout from '../../../../shared/layouts/default/default.layout';

const ApplicationsListContainer = React.lazy(
  () => import('../../containers/applications-list/applications-list.container')
);

const ApplicationsDetailsContainer = React.lazy(
  () => import('../../containers/applications-details/applications-details.container')
);

export const ApplicationsRoutesConfig: RouteObject[] = [
  {
    index: true,
    element: (
      <Navigate to={(APP_ROUTES.APPLICATIONS_MODULE.CHILDREN as RouteConfig).LIST.FULL_PATH} />
    )
  },
  {
    path: (APP_ROUTES.APPLICATIONS_MODULE.CHILDREN as RouteConfig).LIST.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[
            UserRole.FINANCE_MERCHANT_AGENT,
            UserRole.FINANCE_MERCHANT_MANAGER,
            UserRole.FINANCE_MERCHANT_ADMIN,
            UserRole.ADMIN
          ]}
          component={ApplicationsListContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.APPLICATIONS_MODULE.CHILDREN as RouteConfig).DETAILS.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[
            UserRole.FINANCE_MERCHANT_AGENT,
            UserRole.FINANCE_MERCHANT_MANAGER,
            UserRole.FINANCE_MERCHANT_ADMIN,
            UserRole.ADMIN
          ]}
          component={ApplicationsDetailsContainer}
        />
      </React.Suspense>
    )
  }
];
