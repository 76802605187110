if (process.env.NODE_ENV === 'development') {
  // tslint:disable: no-console
  console.group();
  console.info('*** GA Tracking ID *** ', process.env.REACT_APP_GA_ID);
  console.info('*** LogRocket Tracking ID *** ', process.env.REACT_APP_LOG_ROCKET_ID);
  console.info('*** Amplitude Tracking ID *** ', process.env.REACT_APP_AMPLITUDE_ID);
  console.groupEnd();
}

export const TRACKING_GA_APP_ID = process.env.REACT_APP_GA_ID || 'UA-XXXXXX-X';
export const TRACKING_AMPLITUDE_ID = process.env.REACT_APP_AMPLITUDE_ID || 'XXXXXXXXXX';

export const SURVEY_MODULE_TRACKING = {
  SUB_VIEWS: {},
  EVENTS: {}
};
