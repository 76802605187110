import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import middleware from '../middleware';
import rootReducer from '../reducers/index';

const ReduxStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(...middleware)
});

export default ReduxStore;
