import { ApiResponse } from 'apisauce';
import { UserAuthModel } from '../../models/user-auth.model';
import { ErrorInfo } from '../../interfaces/error-info.interface';
import api from '../../config/http.config';
import { ResponseModel } from '../../interfaces/response-model.interface';
import { AddUserDataModel, UserModel } from '../../models/user.model';
import debugConfig from '../../config/debug.config';
import userApiFixtureService from '../fixtures/user-api.service';

const apis = {
  registerUser: (email: string, password: string): Promise<ApiResponse<UserAuthModel, ErrorInfo>> =>
    api.post('/auth/register', { email, password }),
  loginUser: (email: string, password: string): Promise<ApiResponse<UserAuthModel, ErrorInfo>> =>
    api.post('/auth/login', { email, password }),
  changePassword: (
    token: string,
    password: string
  ): Promise<ApiResponse<UserAuthModel, ErrorInfo>> =>
    api.post('/auth/changePassword', { token, password }),
  forgotPassword: (email: string): Promise<ApiResponse<{}, ErrorInfo>> =>
    api.post('/auth/forgotPassword', { email }),
  validateToken: (): Promise<ApiResponse<{}, ErrorInfo>> => api.get('/auth/verifyToken'),
  refreshToken: (refreshToken: string): Promise<ApiResponse<UserAuthModel, ErrorInfo>> =>
    api.post('/auth/refreshToken', { refreshToken }),
  getAllUsers: (): Promise<ApiResponse<ResponseModel<UserModel[]>, ErrorInfo>> =>
    api.get('/users/'),
  addUser: (userModel: UserModel): Promise<ApiResponse<ResponseModel<UserModel>, ErrorInfo>> =>
    api.post('/users/', userModel),
  getUserByPhoneNumber: (
    phone: string
  ): Promise<ApiResponse<ResponseModel<UserModel>, ErrorInfo>> =>
    api.post('/users/searchOne', { phone }),
  addUnverifiedUser: (
    userData: AddUserDataModel
  ): Promise<ApiResponse<ResponseModel<AddUserDataModel>, ErrorInfo>> =>
    api.post('/users/addUser', userData)
};

// TODO: Enhance the way of mocking APIs
export const userApi = debugConfig.useFixtures ? userApiFixtureService : apis;
