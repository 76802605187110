import { MenuItems } from '../enums/side-menu.enum';
import { UserRole } from '../enums/user-role.enum';
import { RouteConfig } from '../interfaces/routes-config.interface';

if (process.env.NODE_ENV === 'development') {
  // tslint:disable: no-console
  console.group();
  console.info('*** PUBLIC PATH *** ', process.env.PUBLIC_URL);
  console.info('*** BE ROUTE ***', process.env.REACT_APP_BE_API_ENDPOINT);
  console.groupEnd();
}

// tslint:enable: no-console
export const PUBLIC_PATH = process.env.PUBLIC_URL || '';
export const SERVER_BASE_URL = process.env.REACT_APP_BE_API_ENDPOINT;
export const HOST_NAME = '//' + window.location.host;
export const WEBSITE_HOME = process.env.REACT_APP_WEBSITE_BASE || 'https://carofi.app/admin/';
import { MenuIcons } from './menu-icons';

export const APP_ROUTES: RouteConfig = {
  AUTH_MODULE: {
    PATH: '/auth',
    FULL_PATH: '/auth',
    NAME: 'auth',
    IS_MENU: false,
    CHILDREN: {
      LOGIN: {
        PATH: '/login',
        FULL_PATH: '/auth/login',
        NAME: 'Sign in',
        IS_MENU: false
      },
      RESET_PASSWORD: {
        PATH: '/reset-password',
        FULL_PATH: '/auth/reset-password',
        NAME: 'Reset password',
        IS_MENU: false
      },
      FORGOT_PASSWORD: {
        PATH: '/forgot-password',
        FULL_PATH: '/auth/forgot-password',
        NAME: 'Forgot password',
        IS_MENU: false
      },
      UNAUTHORIZED: {
        PATH: '/unauthorized',
        FULL_PATH: '/auth/unauthorized',
        NAME: 'Unauthorized',
        IS_MENU: false
      }
    }
  },
  APPLICATIONS_MODULE: {
    PATH: '/applications',
    FULL_PATH: '/applications',
    DISPLAY_NAME: 'Applications',
    NAME: 'applications',
    IS_MENU: true,
    PERMITTED_ROLES: [
      UserRole.FINANCE_MERCHANT_AGENT,
      UserRole.FINANCE_MERCHANT_MANAGER,
      UserRole.FINANCE_MERCHANT_ADMIN,
      UserRole.ADMIN,
      UserRole.ADMIN
    ],
    ICON: MenuIcons.CUSTOMERS_MODULE,
    CHILDREN: {
      LIST: {
        PATH: '/list',
        FULL_PATH: '/applications/list',
        NAME: 'List',
        IS_MENU: true
      },
      DETAILS: {
        PATH: '/applications/details',
        FULL_PATH: '/applications/:id',
        NAME: 'Details',
        IS_MENU: false
      }
    }
  }
};
