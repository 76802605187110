/**
 * UserRole enum to define survey types
 */
export enum UserRole {
  ADMIN = 'admin',
  FINANCE_MERCHANT_AGENT = 'financeMerchantAgent',
  FINANCE_MERCHANT_MANAGER = 'financeMerchantManager',
  FINANCE_MERCHANT_ADMIN = 'financeMerchantAdmin',
  GUEST = 'guest'
}
