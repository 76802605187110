import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { APP_ROUTES } from '../../../../shared/config/routes-config';
import { RouteConfig } from '../../../../shared/interfaces/routes-config.interface';
import UnauthorizedContainer from '../../containers/unauthorized/unauthorized.container';

const LoginContainer = React.lazy(() => import('../../containers/login/login.container'));
const ResetPasswordContainer = React.lazy(
  () => import('../../containers/reset-password/reset-password.container')
);
const ForgotPasswordContainer = React.lazy(
  () => import('../../containers/forgot-password/forgot-password.container')
);

export const AuthRoutesConfig: RouteObject[] = [
  {
    index: true,
    element: <Navigate to={(APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).LOGIN.FULL_PATH} />
  },
  {
    path: (APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).LOGIN.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <LoginContainer />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).FORGOT_PASSWORD.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <ForgotPasswordContainer />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).RESET_PASSWORD.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <ResetPasswordContainer />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).UNAUTHORIZED.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <UnauthorizedContainer />
      </React.Suspense>
    )
  }
];
