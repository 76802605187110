import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ReactComponent as ExpandMore } from '../../../../../assets/svgs/side-bar-icons/expand-more.icon.svg';
import { ReactComponent as ExpandLess } from '../../../../../assets/svgs/side-bar-icons/expand-less.icon.svg';
import { CustomMenuItemProps } from '../shared/types/types';
import ListItemButton from '../shared/components/styled-list-item';
import { useTheme } from '@mui/material/styles';

const MultiLevel = (props: CustomMenuItemProps) => {
  const { item, setIsActive, isActive, selectedIndex, setSelectedIndex } = props;
  const { CHILDREN: children } = item;

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const { logo: Logo, activeLogo: ActiveLogo } = item.ICON;

  const theme = useTheme();

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    children?.map((nestedItem) => {
      if (pathname.includes(nestedItem.NAME)) setOpen(true);
    });
  }, []);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    itemName: string
  ) => {
    setSelectedIndex(itemName);
  };

  const handleMouseEnter = useCallback(() => {
    setIsActive({ [item.NAME]: true });
  }, [item]);

  const handleMouseLeave = useCallback(() => {
    setIsActive({ [item.NAME]: false });
  }, [item]);

  return (
    <Box
      sx={{
        bgcolor: open ? theme.palette.action.active : theme.palette.common.white,
        borderRadius: theme.spacing(1),
        mb: theme.spacing(1)
      }}
    >
      <ListItemButton
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <ListItemIcon>{isActive[item.NAME] || open ? <ActiveLogo /> : <Logo />}</ListItemIcon>

        <ListItemText primary={item.DISPLAY_NAME} />

        {open ? <ExpandMore /> : <ExpandLess />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children?.map((nestedItem, key) => {
            const selected =
              selectedIndex === nestedItem.NAME || nestedItem.NAME === pathname.split('/')[1];

            return (
              <ListItemButton
                nested
                key={key}
                onClick={(e) => {
                  handleListItemClick(e, nestedItem.NAME);
                  navigate(nestedItem.FULL_PATH);
                }}
                selected={selected}
              >
                <ListItemText primary={nestedItem.DISPLAY_NAME} />
              </ListItemButton>
            );
          })}
        </List>
      </Collapse>
    </Box>
  );
};

export default MultiLevel;
