import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../shared/components/core-ui/LegacyButton/button.component';
import { connect, useSelector } from 'react-redux';
import styles from './unauthorized.module.scss';
import { useNavigate } from 'react-router-dom';

import { APP_ROUTES, WEBSITE_HOME } from '../../../../shared/config/routes-config';
import { RouteConfig } from '../../../../shared/interfaces/routes-config.interface';
import { cleanupUserData } from '../../../../shared/services/helpers/auth-helper/auth-helper.service';
import { RootState } from '../../../../shared/redux/reducers';
import { trackPage } from '../../../../shared/services/utils/tracking/tracking.service';

// export type UnauthorizedContainerProps = ReturnType<typeof mapStateToProps>;

/**
 * functional component UnauthorizedContainer
 */
export const UnauthorizedContainer: React.FC = () => {
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state: RootState) => !!state.auth.accessToken);
  const { t } = useTranslation();

  useEffect(() => {
    trackPage('login');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles['unauthorized-container']}>
      <div className={styles['unauthorized-container__wrapper']}>
        <h1 className={styles['unauthorized-container__wrapper__title']}>
          {t('AUTH_MODULE.UNAUTHORIZED_CONTAINER.TITLE')}
        </h1>
        <p className={styles['unauthorized-container__wrapper__sub_title']}>
          {t('AUTH_MODULE.UNAUTHORIZED_CONTAINER.DESCRIPTION')}
        </p>
      </div>
      <div className={styles['unauthorized-container__wrapper__submit-button']}>
        <Button
          onClick={() => {
            window.location.href = WEBSITE_HOME;
          }}
          tracking={{
            action: 'switch-account',
            category: 'auth',
            label: 'unauthorized'
          }}
          size={ButtonSize.large}
        >
          {t('AUTH_MODULE.UNAUTHORIZED_CONTAINER.HOME')}
        </Button>
        <Button
          onClick={() => {
            cleanupUserData();
            navigate((APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).LOGIN.FULL_PATH);
          }}
          tracking={{
            action: 'home',
            category: 'auth',
            label: 'unauthorized'
          }}
          type={ButtonType.primaryInverted}
          size={ButtonSize.large}
        >
          {t('AUTH_MODULE.UNAUTHORIZED_CONTAINER.SWITCH_ACCOUNT')}
        </Button>
      </div>
    </div>
  );
};

export default UnauthorizedContainer;
